import Api from '../data/API';
export async function getList(params: any) {
  return Api.postWithAuth('/admin/api/investment_projects/getInvestmentProjectsList', params);
}
export async function auditor(params: any) {
  return Api.postWithAuth('/admin/api/investment_projects/setStatus', params);
}
export async function getIntentionList(params: any) {
  return Api.postWithAuth('/admin/api/investment_projects/intention/list', params);
}
export async function auditorIntention(params: any) {
  return Api.postWithAuth('/admin/api/investment_projects/intention/setStatus', params);
}
export async function getProjectOptions(params: any) {
  return Api.postWithAuth('/admin/api/investment_projects/getProjectOptions', params);
}
export async function deleteInvestmentProject(params: any) {
  return Api.postWithAuth('/admin/api/investment_projects/delete', params);
}

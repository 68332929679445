import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { Button, Col, Form, Input, message, Row, Select, Space, Switch, Upload } from 'antd';
import {
	addWenzhang,
	findDetails,
	findWenzhangTagList,
	getWenzhangType,
	updateWenzhang,
} from '../../../services/WenzhangService';
import Api from '../../../data/API';
import { PlusOutlined } from '@ant-design/icons/lib';
import WangEditor from '../../../components/WangEditor';
import DAL from '../../../data/DAL';
import {RcFile} from "antd/lib/upload";
import Resizer from 'react-image-file-resizer';
const AddWenzhang: React.FC = (props: any) => {
	useEffect(() => {
		getWenzhangTag();
		findWenzhangType();
		if (type == 'update') {
			getData();
		} else {
			let data: any = {source: '自有平台'};
			form.setFieldsValue(data);
		}
	}, []);

	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	let type = DAL.dal.getQueryString('state');
	let wenzhangUuid = DAL.dal.getQueryString('wenzhangUuid');
	const [wenzhangType, setWenzhangType] = useState([]);

	const [commentStatus, setCommentStatus] = useState('');
	const [isFullscreen, setIsFullscreen] = useState('');
	const [wenzhangTagList, setWenzhangTagList] = useState([]);
	const [wenzhangNeirong, setWenzhangNeirong] = useState('');
	const [tupianUrl, setTupianUrl] = useState('');
	const [dateKey, setdateKey] = useState(new Date().getTime().toString())
	const [source, setSource] = useState('自有平台');
	const getWenzhangTag = async () => {
		const res = await findWenzhangTagList({ wyUuid: localStorage.getItem('wyUuid') });
		if (res.err === 0) {
			console.log('res.data', res.data);
			setWenzhangTagList(res.data);
		}
	};

	const findWenzhangType = async () => {
		const res = await getWenzhangType({});
		console.log('res:::', res);
		let cate: any = [];
		if (res.err === 0) {
			res.data.map((item: any) => {
				cate[item.uuid] = item.name;
			});
			setWenzhangType(res.data);
		}
	};

	const getData = async () => {
		const res = await findDetails({ uuid: wenzhangUuid });
		console.log('获得详细信息', res.data);
		const formData: any = {
			wenzhangBiaoti: res.data.wenzhangBiaoti,
			wenzhangNeirong: res.data.zhuti,
			wEditorValue: res.data.zhuti,
			typeUuid: res.data.typeUuid,
			commentStatus: res.data.commentStatus,
			faBuRen: res.data.fabuRenyuan,
			isFullscreen: res.data.isFullscreen,
			seoTitle: res.data.seoTitle,
			seoDesc: res.data.seoDesc,
			seoKeywords: res.data.seoKeywords,
			tagId: [...res.data.tagIds],
			source: res.data.source || '自有平台',
			wenZhangUrl: res.data.wenZhangUrl,
			isSettop: res.data.isSettop || 0,
		};

		setCommentStatus(res.data.commentStatus);
		setIsFullscreen(res.data.isFullscreen);
		setWenzhangNeirong(res.data.zhuti);
		form.setFieldsValue(formData);
		setTupianUrl(res.data.tupianUrl);
		setdateKey(new Date().getTime().toString())
		setSource(res.data.source || '自有平台');
	};
	const getCommentStatus = (checked: boolean) => {
		if (checked) {
			setCommentStatus('开启');
		} else {
			setCommentStatus('关闭');
		}
	};
	const getsFullscreens = (checked: boolean) => {
		if (checked) {
			setIsFullscreen('开启');
		} else {
			setIsFullscreen('关闭');
		}
	};
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};
	const formItemLayout1 = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 2 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};
	const getFileName = (fileList: any) => {
		console.log('fileList', fileList);
		if (fileList.file.status === 'done') {
			setTupianUrl(fileList.file.response.url);

			console.log(`${fileList.file.name}`);
			console.log('群头像', fileList.file.response.url);
		} else if (fileList.file.status === 'error') {
			message.error(`${fileList.file.name} 上传图片失败`);
		}
	};

	const saveTuwen = () => {
		form.validateFields().then(async (data: any) => {
			const formData: any = {
				uuid: wenzhangUuid,
				typeUuid: data.typeUuid,
				faBuRen: data.faBuRen,
				commentStatus: commentStatus,
				wenzhangBiaoti: data.wenzhangBiaoti,
				wenzhangNeirong: wenzhangNeirong,
				isFullscreen: isFullscreen,
				tupianUrl: tupianUrl,
				seoTitle: data.seoTitle,
				seoKeywords: data.seoKeywords,
				seoDesc: data.seoDesc,
				tagIds: data.tagId.toString(),
				wenZhangUrl: data.wenZhangUrl,
				source: data.source,
				isSettop: data.isSettop,
			};
			const res = await updateWenzhang(formData);
			if (res.err === 0) {
				message.success(res.msg);
				window.location.href = '../wenzhang/find_wenzhang_list';
			} else {
				message.error(res.msg);
			}
		});
	};
	const zancun = () => {
		form.validateFields().then(async (data: any) => {
			console.log('data::', data);
			const formData = {
				typeUuid: data.typeUuid,
				commentStatus: commentStatus,
				faBuRen: data.faBuRen,
				wenzhangBiaoti: data.wenzhangBiaoti,
				tupianUrl: tupianUrl,
				status: '草稿',
				isFullscreen: isFullscreen,
				wenzhangNeirong: wenzhangNeirong,
				seoTitle: data.seoTitle,
				seoKeywords: data.seoKeywords,
				seoDesc: data.seoDesc,
				tagIds: data.tagId === undefined || data.tagId === null ? '' : data.tagId.toString(),
				wenZhangUrl: data.wenZhangUrl,
				source: data.source,
				isSettop: data.isSettop,
			};
			const res = await addWenzhang(formData);
			if (res.err === 0) {
				message.success('保存成功');
				window.location.href = '../wenzhang/find_wenzhang_list';
			} else {
				message.error(res.msg);
			}
		});
	};

	const fanhui = () => {
		window.location.href = '../wenzhang/find_wenzhang_list';
	};
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>上传封面图</div>
		</div>
	);
	const resizeFile = (file: RcFile) => {
		return new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1028,
				1028,
				'jpeg',
				90,
				0,
				(uri) => {
					resolve(uri);
				},
				'file'
			);
		});
	};

	const transformFile = (file: RcFile) => {
		return resizeFile(file)
			.then((url: any) => {
				console.log('文件大小：：：', file.size, url.size);
				return url;
			})
			.catch((e: any) => {
				console.log('发生异常', e);
				return file;
			});
	};
	return (
		<>
			<Row style={{ marginBottom: 4 }}>
				<Col>
					<span className="addTitle">{type === 'update' ? '编辑资讯' : '创建资讯'}</span>
				</Col>
			</Row>
			<div className="content">
				<div className="add-tuwen-div">
					<Form {...formItemLayout} form={form}>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '50%' }}>
								<FormItem label={'文章来源'} name={'source'} rules={[{ required: true }]}>
									<Select defaultValue={source} onChange={(value: string) => setSource(value)}>
										<Select.Option value={'自有平台'}>自有平台</Select.Option>
										<Select.Option value={'微信公众号'}>微信公众号</Select.Option>
									</Select>
								</FormItem>
								<FormItem label={'标题'} name={'wenzhangBiaoti'} rules={[{ required: true }]}>
									<Input />
								</FormItem>
								<FormItem label={'作者'}  rules={[{ required: true }]} name={'faBuRen'}>
									<Input />
								</FormItem>
								<FormItem label={'类目'} name={'typeUuid'} rules={[{ required: true }]}>
									<Select style={{ width: '100%' }}>
										{wenzhangType.map((item: any) => {
											return (
												<Select.Option key={item.uuid} value={item.uuid}>
													{item.name}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
								<FormItem label={'标签'} name={'tagId'} hidden={source === '微信公众号'}>
									<Select
										allowClear
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%' }}
									>
										{wenzhangTagList.map((item: any) => {
											return (
												<Select.Option key={item.id} value={item.id}>
													{item.name}
												</Select.Option>
											);
										})}
									</Select>
								</FormItem>
								<FormItem label={'文章地址'} name={'wenZhangUrl'} hidden={source !== '微信公众号'}>
									<Input />
								</FormItem>
								<FormItem label={'置顶'} name={'isSettop'}>
									<Select>
										<Select.Option value={0}>不置顶</Select.Option>
										<Select.Option value={1}>置顶</Select.Option>
									</Select>
								</FormItem>
								<FormItem
									hidden={source === '微信公众号'}
									label={'是否开启评论'}
									name={'commentStatus'}
									tooltip={'开启评论后，用户可以对文章评论（只支持客户端是APP时评论）'}
								>
									<Switch
										checkedChildren="开启"
										unCheckedChildren="关闭"
										checked={commentStatus === '开启'}
										onChange={getCommentStatus}
									/>
								</FormItem>
								<FormItem
									hidden={source === '微信公众号'}
									label={'全屏模式'}
									name={'isFullscreen'}
									tooltip={'开启全屏模式后，只显示文章详情，文章标题、作者、时间等信息都不会显示'}
								>
									<Switch
										checkedChildren="开启"
										unCheckedChildren="关闭"
										checked={isFullscreen === '开启'}
										onChange={getsFullscreens}
									/>
								</FormItem>
								<FormItem hidden={source === '微信公众号'} label={'SEO标题'} name={'seoTitle'}>
									<Input />
								</FormItem>
								<FormItem hidden={source === '微信公众号'} label={'SEO关键词'} name={'seoKeywords'}>
									<Input />
								</FormItem>
								<FormItem hidden={source === '微信公众号'} label={'SEO描述'} name={'seoDesc'}>
									<Input />
								</FormItem>
							</div>
							<div style={{ width: '50%' }}>
								<Row justify={'space-between'}>
									<Col>
										<Upload
											name="image"
											listType="picture-card"
											className="avatar-uploader1"
											showUploadList={false}
											action={Api.apiRootPath + '/api/picture/upload_qiniu_two'}
											onChange={getFileName}
											beforeUpload={transformFile}
											accept={'.jpg,.png'}
										>
											{tupianUrl !== null && tupianUrl !== undefined && tupianUrl !== '' ? (
												<img
													alt="avatar"
													style={{ width: 128, height: 128, objectFit: 'cover' }}
													src={Api.cdnRootPath + tupianUrl}
												/>
											) : (
												uploadButton
											)}
										</Upload>
									</Col>
									<Col style={{ textAlign: 'right' }}>
										<Space>
											<Button onClick={zancun} type="primary" hidden={type === 'update'}>
												保存
											</Button>
											<Button onClick={saveTuwen} type="primary" hidden={type === 'add'}>
												保存
											</Button>
											<Button onClick={fanhui} hidden={type === 'add'}>
												返回
											</Button>
										</Space>
									</Col>
								</Row>
							</div>
						</div>
						<FormItem
							hidden={source === '微信公众号'}
							{...formItemLayout1}
							label={'详情'}
							name={'wenzhangNeirong'}
						>
							<WangEditor
								key={dateKey.toString()}
								value1={wenzhangNeirong}
								changeCallback={(res: any) => setWenzhangNeirong(res)}
							/>
						</FormItem>
					</Form>
				</div>
			</div>
		</>
	);
};
export default AddWenzhang;

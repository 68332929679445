import React, {useEffect, useRef, useState} from 'react';
import { ActionType } from '@ant-design/pro-table';
import AuthorizedView from '../../util/AuthorizedView';
import GSTable from '../../components/GSTable';
import ProxyForm from '../../components/ProxyForm';
import { getUserList, updateUserInfoPc } from '../../services/UserService';
import { Button, Form, Input, message, Modal, Select, Space, Switch } from "antd";
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import {getWuyeProj} from "../../services/WuyeService";
import {getVersionController} from "../../services/FoundationSetupService";
const { Search } = Input;
const { confirm } = Modal;

const UserList: React.FC = () => {
	useEffect(()=>{
		getAllProject().then()
	},[])
	const [projects,setProjects] = useState([])
	const [search, setSearch] = useState('');
	const actionRef = useRef<ActionType>();
	const [form] = Form.useForm<FormInstance>();
	const FormItem = Form.Item;
	const [visible, setVisible] = useState(false);
	const [isCertification, setIsCertification] = useState(false);
	const [appXcxProjects,setAppXcxProjects] = useState([])
	const Option = Select.Option
	const [appId,setAppId] = useState("")
	const columns = [
		{
			title: '游民ID',
			width: 100,
			key: 'idNumber',
			dataIndex: 'idNumber',
		},
		{
			title: '昵称',
			width: 100,
			key: 'nickname',
			dataIndex: 'nickname',
		},
		{
			title: '性别',
			width: 100,
			key: 'sex',
			dataIndex: 'sex',
		},
		{
			title: '年龄',
			width: 100,
			key: 'age',
			dataIndex: 'age',
		},
		{
			title: '联系方式',
			width: 100,
			key: 'contactNumber',
			dataIndex: 'contactNumber',
		},
		{
			title: '邮箱',
			width: 100,
			key: 'email',
			dataIndex: 'email',
		},
		{
			title: '活动区域',
			width: 100,
			key: 'address',
			dataIndex: 'address',
		},

		{
			title: '星座',
			width: 100,
			key: 'constellation',
			dataIndex: 'constellation',
		},
		{
			title: '职业',
			width: 100,
			key: 'occupation',
			dataIndex: 'occupation',
		},
		{
			title: 'MBTI',
			width: 100,
			key: 'mbti',
			dataIndex: 'mbti',
		},
		{
			title: '操作',
			width: 100,
			hideInTable: true,
			key: 'active',
			dataIndex: 'active',
			render: (text: string, record: any) => {
				return (
					<AuthorizedView needAuthority={'user_management:update'}>
						<a
							onClick={() => {
								openModel(record);
							}}
						>
							修改
						</a>
					</AuthorizedView>
				);
			},
		},
	];
	const getAllProject = async ()=>{
		const res = await getVersionController({})
		if(res.err === 0){
			setProjects(res.data)
			getProjInfo(res.data)
		}
	}
	const filterAppId = (value:any)=>{
		if(value!==null&&value!==''&&value!==undefined){
			console.log("projects:::",projects)
			const items = projects.filter((item:any)=>{
				return item.typeValue == value
			})
			console.log("items::",items)
			const item:any = items[0]
			if (item) {
				return item.name
			} else {
				return  "-"
			}
		}else{
			return "-"
		}
	}
	const getProjInfo = async (projects:any) => {
		const res = await getWuyeProj({});
		if (res.err === 0) {
			let datas: any = new Set();
			const appXcxs = res.data.appXcx;
			if (appXcxs !== undefined && appXcxs !== null && appXcxs !== '') {
				const arr1 = appXcxs.split(',');
				arr1.map((item: any) => {
					projects.map((item1: any) => {
						if (item === item1.typeValue) {
							datas.add(item1);
						}
					});
				});
				const arr:any = [...datas]
				if(arr.length === 0){
					message.warning("没有可查询的应用项目，请联系管理员配置应用")
					return
				}else{
					setAppXcxProjects(arr)
					setAppId(arr[0].typeValue)
					if (actionRef.current) {
						actionRef.current.reloadAndRest()
					}
				}

			}
		}
	};
	const updateInfo = () => {
		setVisible(false);
		confirm({
			title: '您确定修改该用户信息?',
			content: '',
			onOk() {
				updateUserInfo();
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const openModel = (record: any) => {
		const FormData: any = {
			loginId: record.loginId,
			isCertification: record.isCertification === '1',
			bankcardNo: record.bankcardNo,
			id: record.id,
		};
		setIsCertification(record.isCertification === '1');
		form.setFieldsValue(FormData);
		setVisible(true);
	};

	const updateUserInfo = () => {
		form.validateFields().then(async (data: any) => {
			const FormData: any = {
				id: data.id,
				loginId: data.loginId,
				bankcardNo: data.bankcardNo,
				isCertification: data.isCertification ? '1' : '0',
			};
			if (data.bankcardNo != '' && data.bankcardNo != undefined) {
				let pattern = /^([1-9]{1})(\d{14}|\d{18})$/;
				if (!pattern.test(data.bankcardNo)) {
					message.warning('请输入正确的银行卡');
					return;
				}
			}
			const res = await updateUserInfoPc(FormData);
			if (res.err === 0) {
				message.success('修改成功');
				if (actionRef.current) {
					actionRef.current.reload();
				}
			} else {
				message.error(res.msg);
			}
			setVisible(false);
		});
	};

	const tableSearchBarRender = () => [
		<Space>
			{/*<Select*/}
			{/*	value={appId}*/}
			{/*	onChange={(value: any) => {*/}
			{/*		setAppId(value);*/}
			{/*		actionRef.current.reloadAndRest()*/}
			{/*	}}*/}
			{/*>*/}
			{/*	{appXcxProjects.length > 0*/}
			{/*		? appXcxProjects.map((item: any) => {*/}
			{/*			return <Option value={item.typeValue}>{item.name}</Option>;*/}
			{/*		})*/}
			{/*		: ''}*/}
			{/*</Select>*/}
			<Input
				placeholder="请输入手机号"
				onChange={(e) => {
					setSearch(e.target.value);

				}}
			/>
			<Button type={"primary"} onClick={() =>{
				if (actionRef.current) {
					actionRef.current.reloadAndRest();
				}
			}}>查询</Button>
		</Space>

	];
	const getData = async (params) => {
		let res = await getUserList({
			page: params.current,
			size: params.pageSize,
			search: search,
			// appId: appId
		});
		if (res.err != 0) {
			message.error('返回失败');
		}
		return {
			data: res.data,
			total: res.total,
			success: true,
		};

	}
	return (
		<div className={'content-big'}>
			<GSTable
				columns={columns}
				actionRef={actionRef}
				rowKey={'userUuid'}
				searchBarRender={tableSearchBarRender()}
				request={async (params: any = {}, sort: any, filter: any) => {
					return getData(params)
				}}
			/>

			<Modal
				title={'修改用户'}
				onOk={updateInfo}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<ProxyForm form={form}>
					<FormItem label={'id'} name={'id'} hidden={true}>
						<Input />
					</FormItem>
					<FormItem label={'登录帐号'} name={'loginId'}>
						<Input placeholder={'请输入登录帐号'} />
					</FormItem>
					<FormItem label={'银行卡'} name={'bankcardNo'}>
						<Input placeholder={'请输入银行卡号'} />
					</FormItem>
					<FormItem label={'是否实名认证'} name={'isCertification'}>
						<Switch
							checked={isCertification}
							onChange={(value) => {
								setIsCertification(value);
							}}
						/>
					</FormItem>
				</ProxyForm>
			</Modal>
		</div>
	);
};
export default UserList;

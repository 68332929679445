import Api from '../data/API';

export async function getList(params: any) {
  return Api.postWithAuth('/admin/api/shop/getShopTypeList', params);
}
export async function addShopType(params: any) {
  return Api.postWithAuth('/admin/api/shop/addShopType', params);
}
export async function updateShopType(params: any) {
  return Api.postWithAuth('/admin/api/shop/updateShopType', params);
}
export async function deleteShopType(params: any) {
  return Api.postWithAuth('/admin/api/shop/deleteShopType', params);
}

export async function getShopSkuList (params: any) {
  return Api.postWithAuth('/admin/api/shop/getShopSkuList', params);

}
export async function addShopSku (params: any) {
  return Api.postWithAuth('/admin/api/shop/addShopSku', params);

}
export async function updateShopSku (params: any) {
  return Api.postWithAuth('/admin/api/shop/updateShopSku', params);

}
export async function deleteShopSku (params: any) {
  return Api.postWithAuth('/admin/api/shop/deleteShopSku', params);

}

import React, {useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import {addShopType, deleteShopType, getList, updateShopType} from "../../../services/Shop";
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {Button, Form, Image, Input, InputNumber, message, Modal, Space} from "antd";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import API from "../../../data/API";
import DebounceSelect from "../../../components/DebounceSelect";
import ShopSkuList from "../components/ShopSkuList";

const Index: React.FC = () => {
  const actionRef = useRef<ActionType>()
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [search, setSearch] = useState('');
  const [addVisible, setAddVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [shopTypeId, setShopTypeId] = useState('');
  const [shopTypeItem, setShopTypeItem] = useState({})
  const [roomVisible, setRoomVisible] = useState(false)
  const columns = [
    {
      title: '类目名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '排序',
      dataIndex: 'sortNum',
      key: 'sortNum',
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      valueType: 'dateTime',
      key: 'createdTime',
      hideInForm: true
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      hideInForm: true,
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <a onClick={() => {
            setUpdateVisible(true)
            setShopTypeItem(record)
          }}>编辑</a>
          <a onClick={()=> {
            setShopTypeId(record.id);
            setRoomVisible(true);
          }}>商品列表</a>
          <a onClick={() => {
            Modal.confirm({
              title: '你确定要删除' + record.name + '吗？',
              okType: 'danger',
              onOk: async () => {
                const data: any = {
                  id: record.id
                };
                const res = await deleteShopType(data);
                if (res.err === 0) {
                  message.success('删除成功');
                  actionRef.current.reload();
                } else {
                  message.warning(res.msg);
                }
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }}>删除</a>
        </Space>
      ),
    },
  ];
  const tableSearchBarRender = () => [
    <Input placeholder={'名称'} onChange={(event: any) => {
      setSearch(event.target.value)
    }}/>,
    <Button type="primary" onClick={() => {
      if (actionRef.current) {
        actionRef.current.reload()
      }
    }}>查询</Button>
  ];
  const addItem = async (data) => {
    console.log('data', data.imgs);
    let rsp = await addShopType(data);
    if (rsp.err === 0) {
      setAddVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  const updateItem = async (data) => {
    let rsp = await updateShopType({...data, id: shopTypeItem.id});
    if (rsp.err === 0) {
      setUpdateVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  return <>
    <GSTable
      columns={columns}
      actionRef={actionRef}
      rowKey={"id"}
      searchBarRender={tableSearchBarRender()}
      operationsBarRender={[
        <Button type={"primary"} onClick={() => {
          addForm.resetFields()
          setAddVisible(true)
        }}>添加</Button>
      ]}
      request={async (params: any = {}, sort: any, filter: any) => {
        const res = await getList({
          page: params.current,
          size: params.pageSize,
          name: search,
        });
        if (res.err !== 0) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: res.data.list,
          total: res.data.total,
          success: true,
        };
      }}
    />
    <Modal destroyOnClose={true} visible={addVisible} title={'添加类目'} onCancel={() => {
      setAddVisible(false)
    }} footer={null}>
      <GSTable  form={{
        layout: 'horizontal',
        labelCol: {span: 4}
      }} columns={columns} type={'form'} formRef={addForm} onSubmit={addItem} />
    </Modal>
    <Modal destroyOnClose={true} visible={updateVisible} title={'编辑酒店'} onCancel={() => {
      setUpdateVisible(false)
    }} footer={null}>
      <GSTable  form={{
        layout: 'horizontal',
        labelCol: {span: 4},
        initialValues: shopTypeItem
      }} columns={columns} type={'form'} formRef={updateForm} onSubmit={updateItem} />
    </Modal>
    <Modal title={'商品列表'} width={1080} destroyOnClose={true} visible={roomVisible} onCancel={() => {
      setRoomVisible(false);
    }} footer={null} >
      <ShopSkuList shopTypeId={shopTypeId} />
    </Modal>
  </>
}
export default Index;

import React, {useRef, useState} from 'react';
import GSTable from "../../../components/GSTable";
import {addHotel, deleteHotel, getList, updateHotel} from "../../../services/HotelService";
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {Button, Form, Image, Input, InputNumber, message, Modal, Space} from "antd";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import DAL from "../../../data/DAL";
import API from "../../../data/API";
import RoomList from "../components/RoomList";
import DebounceSelect from "../../../components/DebounceSelect";

const Index: React.FC = () => {
  const actionRef = useRef<ActionType>()
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [search, setSearch] = useState('');
  const [addVisible, setAddVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [hotelId, setHotelId] = useState('');
  const [roomVisible, setRoomVisible] = useState(false)
  const columns = [
    {
      title: '酒店名称',
      dataIndex: 'name',
      width: 200,
      key: 'name',
    }, {
      title: '图片',
      dataIndex: 'imgs',
      width: 100,
      key: 'imgs',
      render: (_, record) => <div>
        {
          <Image src={(record.imgs || '').split(',')[0]} style={{width: 100, height: 100}}/>
        }
      </div>
    },
    {
      title: '省份',
      width: 80,
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: '城市',
      width: 80,
      dataIndex: 'base',
      key: 'base',
    },
    {
      title: '地址',
      dataIndex: 'address',
      width: 150,
      key: 'address',
    },
    {
      title: '联系人',
      width: 100,
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: '联系电话',
      width: 100,
      dataIndex: 'ownerPhone',
      key: 'ownerPhone',
    }, {
      title: '添加人',
      width: 100,
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: '创建时间',
      width: 200,
      dataIndex: 'createTime',
      valueType: 'dateTime',
      key: 'createTime',
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <a onClick={() => {
            setUpdateVisible(true)
            updateForm.setFieldsValue({...record, address: [{value: record.address, label: record.address}]})

          }}>编辑</a>
          <a onClick={()=> {
              setHotelId(record.id);
              setRoomVisible(true);
          }}>房间列表</a>
          <a onClick={() => {
            Modal.confirm({
              title: '你确定要删除' + record.name + '吗？',
              okType: 'danger',
              onOk: async () => {
                const data: any = {
                  id: record.id
                };
                const res = await deleteHotel(data);
                if (res.err === 0) {
                  message.success('删除成功');
                  actionRef.current.reload();
                } else {
                  message.warning(res.msg);
                }
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }}>删除</a>
        </Space>
      ),
    },
  ];
  const tableSearchBarRender = () => [
    <Input placeholder={'名称'} onChange={(event: any) => {
      setSearch(event.target.value)
    }}/>,
    <Button type="primary" onClick={() => {
      if (actionRef.current) {
        actionRef.current.reload()
      }
    }}>查询</Button>
  ];
  const addItem = async () => {
    let data = await addForm.validateFields();
    console.log('data', data.imgs);

    let imgs = data.imgs;
    let tempImgs = (imgs || '').split(',');
    let httpImgs: any = [];
    tempImgs.map(imgItem => {
      if (!imgItem.includes('http')) {
        httpImgs.push(API.cdnRootPath + imgItem);
      } else {
        httpImgs.push(imgItem);
      }
    })
    data.imgs = httpImgs.toString();
    data.address = data.address[0].value;
    console.log('data', data);
    let rsp = await addHotel(data);
    if (rsp.err === 0) {
      setAddVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  const updateItem = async () => {
    let data = await updateForm.validateFields();
    let imgs = data.imgs;
    let tempImgs = (imgs || '').split(',');
    let httpImgs: any = [];
    tempImgs.map(imgItem => {
      if (!imgItem.includes('http')) {
        httpImgs.push(API.cdnRootPath + imgItem);
      } else {
        httpImgs.push(imgItem);
      }
    })
    data.imgs = httpImgs.toString();
    data.address = data.address[0].value;
    let rsp = await updateHotel(data);
    if (rsp.err === 0) {
      setUpdateVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  const fetchOptions =  (keyword: string): Promise<any> => {
    return fetch('https://restapi.amap.com/v3/assistant/inputtips?key=31c1f1f4c78a92fdc2dad4256b66d98e&keywords=' + keyword)
      .then((response) => response.json())
      .then((body) =>
        body.tips.map(
          (item) => ({
            key: item.id,
            label:  item.address + "-" +item.name,
            value: item.address + "-" + item.name,
            location: item.location,
            district: item.district
          }),
        ),
      );
  }
  return <>
    <GSTable
      columns={columns}
      actionRef={actionRef}
      rowKey={"id"}
      searchBarRender={tableSearchBarRender()}
      operationsBarRender={[
        <Button type={"primary"} onClick={() => {
          addForm.resetFields()
          setAddVisible(true)
        }}>添加</Button>
      ]}
      request={async (params: any = {}, sort: any, filter: any) => {
        const res = await getList({
          page: params.current,
          size: params.pageSize,
          name: search,
        });
        if (res.err !== 0) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: res.data.data,
          total: res.data.total,
          success: true,
        };
      }}
    />
    <Modal destroyOnClose={true} visible={addVisible} title={'添加酒店'} onCancel={() => {
      setAddVisible(false)
    }} onOk={addItem}>
      <Form form={addForm} labelCol={{span: 6}}>
        <Form.Item rules={[{required: true}]} name={'name'} label={'酒店名称'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'imgs'} label={'图片'}>
          <UploadImgFormItem multiple={true} hiddenImgCorp={true}
                             listType={'picture-card'}
                             heightRatio={1}
                             widthRatio={1}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'abstractStr'} label={'摘要'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'owner'} label={'联系人'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'ownerPhone'} label={'联系人电话'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'address'} label={'地址'}>
          <DebounceSelect style={{width: '100%'}}  onSelect={(value, option) => {
            console.log('options', option);
            let district = option.district;
            let province = "";
            let city = "";
            if (district.includes('省')) {
              province = district.split('省')[0] + '省';
              city = district.split('省')[1];
            }else {
              province = district.split('市')[0] + '市';
              city = district.split(province)[1];
            }
            addForm.setFieldsValue({'lng': option.location.split(',')[0], 'lat': option.location.split(',')[1],'city': province, 'base': city})
          }} maxCount={1} fetchOptions={fetchOptions} mode="multiple"/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'city'} label={'省份'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'base'} label={'城市'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: false}]} name={'lng'} label={'经度'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item rules={[{required: false}]} name={'lat'} label={'纬度'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item initialValue={1} rules={[{required: true}]} name={'sort'} label={'排序'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
      </Form>
    </Modal>
    <Modal destroyOnClose={true} visible={updateVisible} title={'编辑酒店'} onCancel={() => {
      setUpdateVisible(false)
    }} onOk={updateItem}>
      <Form form={updateForm} labelCol={{span: 6}}>
        <Form.Item rules={[{required: true}]} hidden={true} name={'id'} label={'id'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'name'} label={'酒店名称'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'imgs'} label={'图片'}>
          <UploadImgFormItem multiple={true} hiddenImgCorp={true}
                             listType={'picture-card'}
                             heightRatio={1}
                             widthRatio={1}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'abstractStr'} label={'摘要'}>
          <Input.TextArea rows={3}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'owner'} label={'联系人'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'ownerPhone'} label={'联系人电话'}>
          <Input/>
        </Form.Item>
        <Form.Item  rules={[{required: true}]} name={'address'} label={'地址'}>
          <DebounceSelect style={{width: '100%'}} onSelect={(value, option) => {
            let district = option.district;
            let province = "";
            let city = "";
            if (district.includes('省')) {
              province = district.split('省')[0] + '省';
              city = district.split('省')[1];
            }else {
              province = district.split('市')[0] + '市';
              city = district.split(province)[1];
            }
            updateForm.setFieldsValue({'lng': option.location.split(',')[0], 'lat': option.location.split(',')[1],'city': province, 'base': city})
          }} maxCount={1} fetchOptions={fetchOptions} mode="multiple"/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'city'} label={'省份'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'base'} label={'城市'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: false}]} name={'lng'} label={'经度'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item rules={[{required: false}]} name={'lat'} label={'纬度'}>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'sort'} label={'排序'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
      </Form>
    </Modal>
    <Modal title={'房间列表'} width={1000} destroyOnClose={true} visible={roomVisible} onCancel={() => {
      setRoomVisible(false);
    }} footer={null} >
      <RoomList hotelId={hotelId} />
    </Modal>
  </>
}
export default Index;

import React, {useRef, useState} from 'react';
import {ActionType} from "@ant-design/pro-table/lib/typing";
import {Button, Form, Image, Tag, Input, InputNumber, message, Modal, Select, Space, Card} from "antd";
import {getShopSkuList, addShopSku, updateShopSku, deleteShopSku} from "../../../services/Shop";
import API from "../../../data/API";
import GSTable from "../../../components/GSTable";
import UploadImgFormItem from "../../../components/UploadImgFormItem";
import {CloseOutlined} from '@ant-design/icons';

const Index: React.FC<any> = (props: any) => {
  const actionRef = useRef<ActionType>()
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [search, setSearch] = useState('');
  const [addVisible, setAddVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const columns = [
    {
      title: '商品名称',
      dataIndex: 'name',
      width: 200,
      key: 'name',
    },
    {
      title: '图片',
      dataIndex: 'img',
      width: 100,
      key: 'img',
      render: (_, record) => <div>
        {
          <Image src={(record.imgs || '').split(',')[0]} style={{width: 100, height: 100}}/>
        }
      </div>
    },

    {
      title: '介绍',
      width: 150,
      ellipsis: true,
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: '价格',
      dataIndex: 'price',
      width: 80,
      key: 'price',
    }, {
      title: '状态',
      dataIndex: 'status',
      width: 80,
      key: 'status',
      valueEnum: {
        'true': <Tag color={'green'}>上架</Tag>,
        "false": <Tag color={"red"}>下架</Tag>
      }
    },
    {
      title: '排序',
      width: 100,
      dataIndex: 'sortNum',
      key: 'sortNum',
    },
    {
      title: '创建时间',
      width: 200,
      dataIndex: 'createdTime',
      valueType: 'dateTime',
      key: 'createdTime',
    },
    {
      title: '操作',
      width: 100,
      fixed: 'right',
      key: 'action',
      render: (text: string, record: any) => (
        <Space>
          <a onClick={() => {
            record.rules = JSON.parse(record.rules || '[]')
            updateForm.setFieldsValue({...record})
            setUpdateVisible(true)
          }}>编辑</a>
          <a onClick={() => {
            Modal.confirm({
              title: '你确定要删除' + record.name + '吗？',
              okType: 'danger',
              onOk: async () => {
                const data: any = {
                  id: record.id
                };
                const res = await deleteShopSku(data);
                if (res.err === 0) {
                  message.success('删除成功');
                  actionRef.current.reload();
                } else {
                  message.warning(res.msg);
                }
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }}>删除</a>
        </Space>
      ),
    },
  ];
  const tableSearchBarRender = () => [
    <Input placeholder={'名称'} onChange={(event: any) => {
      setSearch(event.target.value)
    }}/>,
    <Button type="primary" onClick={() => {
      if (actionRef.current) {
        actionRef.current.reload()
      }
    }}>查询</Button>
  ];
  const addItem = async () => {
    let data = await addForm.validateFields();
    console.log('data', data.imgs);
    let imgs = data.imgs;
    let tempImgs = (imgs || '').split(',');
    let httpImgs: any = [];
    tempImgs.map(imgItem => {
      if (!(imgItem.includes('http') && imgItem.includes('cdn.shuziyoumin.top'))) {
        httpImgs.push(API.cdnRootPath + imgItem);
      } else {
        httpImgs.push(imgItem);
      }
    })
    data.imgs = httpImgs.toString();
    data.rules = JSON.stringify(data.rules);
    let rsp = await addShopSku({shopTypeId: props.shopTypeId, ...data});
    if (rsp.err === 0) {
      setAddVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  const updateItem = async () => {
    let data = await updateForm.validateFields();
    let imgs = data.imgs;
    let tempImgs = (imgs || '').split(',');
    let httpImgs: any = [];
    tempImgs.map(imgItem => {
      if (!(imgItem.includes('http') && imgItem.includes('cdn.shuziyoumin.top'))) {
        httpImgs.push(API.cdnRootPath + imgItem);
      } else {
        httpImgs.push(imgItem);
      }
    })
    data.imgs = httpImgs.toString();
    data.rules = JSON.stringify(data.rules);
    let rsp = await updateShopSku({shopTypeId: props.shopTypeId, ...data});
    if (rsp.err === 0) {
      setUpdateVisible(false)
      actionRef.current.reload()
    } else {
      message.error(rsp.msg);
    }
  }
  return <>
    <GSTable
      columns={columns}
      actionRef={actionRef}
      rowKey={"id"}
      searchBarRender={tableSearchBarRender()}
      operationsBarRender={[
        <Button type={"primary"} onClick={() => {
          addForm.resetFields()
          setAddVisible(true)
        }}>添加</Button>
      ]}
      request={async (params: any = {}, sort: any, filter: any) => {
        const res = await getShopSkuList({
          page: params.current,
          size: params.pageSize,
          shopTypeId: props.shopTypeId,
          name: search,
        });
        if (res.err !== 0) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: res.data.list,
          total: res.data.total,
          success: true,
        };
      }}
    />
    <Modal destroyOnClose={true} visible={addVisible} title={'添加房间'} onCancel={() => {
      setAddVisible(false)
    }} onOk={addItem}>
      <Form form={addForm} labelCol={{span: 6}}>
        <Form.Item rules={[{required: true}]} name={'name'} label={'商品名称'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'imgs'} label={'图片'}>
          <UploadImgFormItem multiple={true} hiddenImgCorp={true}
                             listType={'picture-card'}
                             heightRatio={1}
                             widthRatio={1}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'note'} label={'介绍'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'price'} label={'价格'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item label="规格">

          <Form.List initialValue={[]} name={'rules'} rules={[{required: true}]}>
            {(fields, {add, remove}) => (
              <div style={{display: 'flex', rowGap: 16, flexDirection: 'column'}}>
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`规格 ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item label="类型" name={[field.name, 'type']}>
                      <Input/>
                    </Form.Item>

                    {/* Nest Form.List */}
                    <Form.Item label="选项">
                      <Form.List name={[field.name, 'options']}>
                        {(subFields, subOpt) => (
                          <div style={{display: 'flex', flexDirection: 'column', rowGap: 16}}>
                            {subFields.map((subField) => (
                              <Space key={subField.key}>
                                <Form.Item noStyle name={[subField.name, 'name']}>
                                  <Input placeholder="名称"/>
                                </Form.Item>
                                <Form.Item noStyle name={[subField.name, 'price']}>
                                  <InputNumber placeholder="价格"/>
                                </Form.Item>
                                <CloseOutlined
                                  onClick={() => {
                                    subOpt.remove(subField.name);
                                  }}
                                />
                              </Space>
                            ))}
                            <Button type="dashed" onClick={() => subOpt.add()} block>
                              + 添加选项
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                ))}

                <Button type="dashed" onClick={() => add()} block>
                  + 添加规格
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'status'} label={'状态'}>
          <Select>
            <Select.Option value={true}>上架</Select.Option>
            <Select.Option value={false}>下架</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item initialValue={1} rules={[{required: true}]} name={'sortNum'} label={'排序'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
      </Form>
    </Modal>
    <Modal destroyOnClose={true} visible={updateVisible} title={'编辑房间'} onCancel={() => {
      setUpdateVisible(false)
    }} onOk={updateItem}>
      <Form form={updateForm} labelCol={{span: 6}}>
        <Form.Item rules={[{required: true}]} hidden={true} name={'id'} label={'id'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'name'} label={'商品名称'}>
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'imgs'} label={'图片'}>
          <UploadImgFormItem multiple={true} hiddenImgCorp={true}
                             listType={'picture-card'}
                             heightRatio={1}
                             widthRatio={1}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'note'} label={'介绍'}>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'price'} label={'价格'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item label="规格">
          <Form.List name={'rules'} rules={[{required: true}]}>
            {(fields, {add, remove}) => (
              <div style={{display: 'flex', rowGap: 16, flexDirection: 'column'}}>
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`规格 ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item label="类型" name={[field.name, 'type']}>
                      <Input/>
                    </Form.Item>

                    {/* Nest Form.List */}
                    <Form.Item label="选项">
                      <Form.List name={[field.name, 'options']}>
                        {(subFields, subOpt) => (
                          <div style={{display: 'flex', flexDirection: 'column', rowGap: 16}}>
                            {subFields.map((subField) => (
                              <Space key={subField.key}>
                                <Form.Item noStyle name={[subField.name, 'name']}>
                                  <Input placeholder="名称"/>
                                </Form.Item>
                                <Form.Item noStyle name={[subField.name, 'price']}>
                                  <InputNumber placeholder="价格"/>
                                </Form.Item>
                                <CloseOutlined
                                  onClick={() => {
                                    subOpt.remove(subField.name);
                                  }}
                                />
                              </Space>
                            ))}
                            <Button type="dashed" onClick={() => subOpt.add()} block>
                              + 添加选项
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                ))}

                <Button type="dashed" onClick={() => add()} block>
                  + 添加规格
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item rules={[{required: true}]} name={'status'} label={'状态'}>
          <Select>
            <Select.Option value={true}>上架</Select.Option>
            <Select.Option value={false}>下架</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item initialValue={1} rules={[{required: true}]} name={'sortNum'} label={'排序'}>
          <InputNumber style={{width: '100%'}}/>
        </Form.Item>
      </Form>
    </Modal>
  </>
}
export default Index;

import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import ShopTypeList from "./pages/ShopTypeList";

export default function ShopMiniApp() {
	const rootUrl = '/shop';
	const menus: MiniAppMenu[] = [
		{
			authority: 'shopManager:list',
			name: '商品类目列表',
			path: 'list',
			component: ShopTypeList,
		},
	];
	const routes: MiniAppRoute[] = [
	];

	return <MiniApp miniAppName={'商品管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
